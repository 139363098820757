import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Image, Tabs, Tab } from "react-bootstrap";
import maplibregl from "maplibre-gl";
import Spinner from "react-bootstrap/Spinner";
import "maplibre-gl/dist/maplibre-gl.css"; // Style MapLibre

function ProfilePage({ memberData, closeModal }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (!memberData || !memberData.addresses || !mapContainer.current) return;

    // Vérifier qu'il y a au moins une adresse avec des coordonnées
    const validAddresses = memberData.addresses.filter(
      addr => addr.latitude && addr.longitude
    );
    if (validAddresses.length === 0) return;

    if (!map.current) {
      // Utiliser la première adresse comme centre initial
      const firstAddress = validAddresses[0];
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
        center: [parseFloat(firstAddress.longitude), parseFloat(firstAddress.latitude)],
        zoom: 13,
      });

      // Ajouter un marqueur pour chaque adresse valide
      validAddresses.forEach((address, index) => {
        const marker = new maplibregl.Marker()
          .setLngLat([parseFloat(address.longitude), parseFloat(address.latitude)])
          .setPopup(
            new maplibregl.Popup().setHTML(`
              <h6>${address.practice_company_name || "Adresse " + (index + 1)}</h6>
              <p>${address.street_with_number}<br>${address.zip} ${address.city}</p>
            `)
          )
          .addTo(map.current);

        // Ajuster les limites pour inclure tous les marqueurs
        if (index === 0) {
          map.current.setCenter([parseFloat(address.longitude), parseFloat(address.latitude)]);
        } else {
          const bounds = map.current.getBounds();
          bounds.extend([parseFloat(address.longitude), parseFloat(address.latitude)]);
          map.current.fitBounds(bounds, { padding: 50 });
        }
      });
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [memberData]);

  if (!memberData) {
    return (
      <div style={{ paddingTop: "300px", paddingBottom: "300px" }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const titlePrefix = memberData.gender_id === 1 ? "Dr" : "Dre";

  // Déterminer l'icône à afficher en fonction des permissions
  const getPermissionIcon = () => {
    if (!memberData.permissions || memberData.permissions.length === 0) return null;

    const perm = memberData.permissions[0]; // Prendre la première permission
    if (perm.permission_state === "Autorisation octroyée" && perm.activity_state === "Actif") {
      return <i className="bi bi-patch-check-fill text-primary ms-2"></i>;
    } else if (perm.permission_state === "Autorisation octroyée" && perm.activity_state === "Inactif") {
      return <i className="bi bi-patch-check text-secondary ms-2"></i>;
    } else if (perm.permission_state === "Pas d'autorisation, autorisation retirée") {
      return <i className="bi bi-ban text-danger ms-2"></i>;
    }
    return null; // Pas d'icône si aucune condition n'est remplie
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col md={3}>
          <Image
            src={memberData.profile_picture 
              ? memberData.profile_picture 
              : (memberData.gender_id === 1 ? "./img/members/male1.png" : "./img/members/female1.png")}
            alt={`${memberData.first_name} ${memberData.name}`}
            roundedCircle
            fluid
            style={{ width: "150px", height: "150px", objectFit: "cover", border: "3px solid #fcb6b3" }}
          />
        </Col>
        <Col md={9}>
          <h2 className="mb-4">
            {titlePrefix} {memberData.first_name} {memberData.name}
            {getPermissionIcon()}
          </h2>
          <p className="mb-1"><strong>Nationalité :</strong> {memberData.nationalities.join(", ") || "Non spécifiée"}</p>
          {/* <p><strong>Année de naissance :</strong> {memberData.year_of_birth || "Non spécifiée"}</p> */}
        </Col>
      </Row>

      <Tabs defaultActiveKey="bio" className="mb-4">
        {/* <Tab eventKey="bio" title="Biographie">
          <div className="tab-content">
            <p>{memberData.biography || "Aucune biographie disponible."}</p>
          </div>
        </Tab> */}
        <Tab eventKey="bio" title="Biographie">
          <div className="tab-content">
            <p
              dangerouslySetInnerHTML={{
                __html: memberData.biography || "Aucune biographie disponible.",
              }}
            />
          </div>
        </Tab>
        <Tab eventKey="specialties" title="Spécialités">
          <div className="tab-content">
            <h5>Spécialités</h5>
            <ul>{memberData.specialties.length > 0 ? memberData.specialties.map((s, i) => <li key={i}>{s}</li>) : <li>Aucune</li>}</ul>
            <h5>Sous-spécialités</h5>
            <ul>{memberData.sub_specialties.length > 0 ? memberData.sub_specialties.map((s, i) => <li key={i}>{s}</li>) : <li>Aucune</li>}</ul>
            <h5>Certifications</h5>
            <ul>{memberData.certifications.length > 0 ? memberData.certifications.map((c, i) => <li key={i}>{c}</li>) : <li>Aucune</li>}</ul>
          </div>
        </Tab>
        <Tab eventKey="diplomas" title="Diplôme">
          <div className="tab-content">
            {memberData.diplomas.length > 0 ? (
              memberData.diplomas.map((d, i) => (
                <div key={i}>
                  <p><strong>Type :</strong> {d.type}</p>
                  <p><strong>Date :</strong> {d.issuance_date}</p>
                  <p><strong>Pays :</strong> {d.country}</p>
                </div>
              ))
            ) : (
              <p>Aucun diplôme disponible.</p>
            )}
          </div>
        </Tab>
        <Tab eventKey="address_contacts" title="Adresse & Contacts">
          <div className="tab-content">
            {memberData.addresses && memberData.addresses.length > 0 ? (
              memberData.addresses.map((addr, index) => (
                <div key={index} className="mb-4">
                  <h5>{addr.practice_company_name || `Adresse ${index + 1}`}</h5>
                  <p className="mb-1"><i className="bi bi-geo-alt-fill"></i>{" "}<strong>Adresse:</strong></p>
                  <p>
                    <a
                      href={
                        /iPhone|iPad|Macintosh/i.test(navigator.userAgent)
                          ? `http://maps.apple.com/?q=${encodeURIComponent(addr.street_with_number + " " + addr.zip + " " + addr.city)}`
                          : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addr.street_with_number + " " + addr.zip + " " + addr.city)}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-dark"
                    >
                      {addr.street_with_number}<br />
                      {addr.zip} {addr.city}
                    </a>
                  </p>
                  <p className="mb-1"><i className="bi bi-telephone-fill"></i> Téléphone :{" "}
                    {addr.phone_numbers ? (
                      <a href={`tel:${addr.phone_numbers}`} className="text-decoration-none text-dark">
                        {addr.phone_numbers}
                      </a>
                    ) : (
                      "Aucun numéro"
                    )}
                  </p>
                  <p className="mb-2"><i className="bi bi-envelope-fill"></i> Email :{" "}
                    {memberData.contact_email ? (
                      <a href={`mailto:${memberData.contact_email}`} className="text-decoration-none text-dark">
                        {memberData.contact_email}
                      </a>
                    ) : (
                      "Non spécifié"
                    )}
                  </p>
                  {addr.website && (
                    <p className="mb-1">
                      <i className="bi bi-globe"></i> Site web :{" "}
                      <a
                        href={addr.website.startsWith('http') ? addr.website : `https://${addr.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none text-dark"
                      >
                        {addr.website}
                      </a>
                    </p>
                  )}
                  
                </div>
              ))
            ) : (
              <p>Aucune adresse disponible.</p>
            )}

            {memberData.addresses.some(addr => addr.latitude && addr.longitude) ? (
              <div ref={mapContainer} style={{ height: "300px", width: "100%", margin: "0", padding: "0" }} />
            ) : (
              <p>Coordonnées GPS non disponibles.</p>
            )}
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default ProfilePage;